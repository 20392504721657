import React, { FC, useEffect } from "react";
import ReactDOM from "react-dom";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "../Typography";
import Variant from "../Typography/Variant";
import styles from './styles.module.css';

/// TODO: MAKE FOCUS TRAP!!
const modalRoot = document.getElementById("modal-root") as HTMLElement;

interface Props {
  children: string | React.ReactElement | (string | React.ReactElement)[]
  title: string
  toggleModal: () => void
}

const SkillModal: FC<Props> = ({ children, title, toggleModal }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', toggleModal)
    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('keydown', toggleModal)
    }
  }, []);
  
  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  }

  return ReactDOM.createPortal(
    <div onClick={toggleModal} className={styles.overlay}>
      <article onClick={stopPropagation} className={styles.content}>
        <header className={styles.header}>
          <Typography
            variant={Variant.h2}
            gutterBottom
          >
            {title}
          </Typography>
          <button onClick={toggleModal} className={styles.closeBtn}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </header>
        {children}
      </article>
    </div>,
    modalRoot
  );
};

export default SkillModal;
