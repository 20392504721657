import React, { FC } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Footer from '../containers/Footer';
import Header from '../containers/Header';
import Home from '../containers/Home';
import styles from './styles.module.css';

const App: FC = () => {
  return (
    <div className={styles.content} style={{ justifyContent: 'center', alignItems: 'center' }}>
      <h1>Coming soon!</h1>
    </div>
  )

  return (
    <Router>
      <div className={styles.content}>
        <div>
          <Header />
          <Routes>
            <Route path='/' element={< Home />}></Route>
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
